import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 400 400"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#8B6AFC",
      d: "M190.5 23.9c-12 1.6-23.9 6.7-33.7 14.5-4.1 3.2-115.3 114.3-118.6 118.4-20 25.3-20.6 60-1.4 85.8 2.8 3.7 112.7 114.2 118.6 119.2 24.6 20.8 60.4 21.7 86.7 2.3 3.5-2.6 114.6-113.2 119.3-118.7 18.7-22.2 21.5-54.7 6.8-79.7-4.1-7-6.3-9.5-18.8-22.2l-10.7-10.8 2.7-1.8c10.3-7 14.7-16 11.3-23.2-4.5-9.5-21.6-19.4-42.9-24.8l-4.2-1.1-.8-1.8C299 67.2 287.5 61.6 273 64.3l-2.2.4-12.2-12.1c-6.7-6.7-13.4-13.1-15-14.3-15.4-11.8-34.3-16.9-53.1-14.4m89.7 43.2c8.3 2.8 15 9.5 17.3 17.2.5 1.8.5 1.8 4.8 2.9 28.7 7.3 45.1 17.2 45.8 27.7.9 12.8-20.1 27.6-57.6 40.8-3.8 1.3-8.5 3.2-10.6 4.4-28.9 14.9-44.9 56.6-39 101.2l.4 3.3-19.3 19.3c-21.5 21.6-21 21.2-23.5 19.9-.4-.2-17.5-17.2-38.1-37.7l-37.3-37.3 3.1-3.4c19.2-21.3 27.3-44.8 27.3-79.8v-4l2.2-5.5c9.7-24.2 24.8-39.4 48.4-48.6 11.8-4.6 29.8-8 42.6-8.1 1 0 1.3-.2 1.9-1.4 5.6-9.7 20.3-14.8 31.6-10.9m-129.1 86.6c-8.2 40.3-13.8 51-33.9 65l-2.1 1.5-8.6-8.6c-9-9-9.8-9.9-9.2-11.8.3-.9 55.6-56.9 55.9-56.5 0-.1-.9 4.6-2.1 10.4m137.3 30.5c14.2 14.2 15.2 15.4 14.9 17.1-.1.6-7.4 8-21.5 22.2l-21.3 21.3-.9-3.3c-6.9-25.8-4.9-43.9 6.7-61.6 2.7-4.1 7.2-10 7.6-10 .1 0 6.7 6.5 14.5 14.3"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#FBFBFB",
      d: "M266.2 66.4c-7 1.5-13.2 5.6-17 11.3l-1.4 2-5.1.2c-43.3 1.8-73.2 21.5-87.3 57.5l-1.8 4.7v5.9c0 33.3-8.9 58.2-27.8 78.1l-2.5 2.6 37.6 37.6 37.6 37.6h3.2l19.7-19.6 19.7-19.6-.5-4.1c-5-42.7 8.1-80.2 34.1-97.6 4.3-2.9 8-4.6 15.2-7.1 38.6-13.4 59.9-28.8 57.8-41.7-1.6-9.8-18.1-19.5-44.8-26.4-3.4-.9-5-1.4-5.1-1.8-4.8-14.7-17.8-22.6-31.6-19.6m14.3 8.3c12.2 6 14.7 22.3 5 32-15.9 15.9-41.6-3-31.6-23.2 1.2-2.5 1.6-2.8 2-1.8.9 2.8 5.4 5.2 10 5.2 6.9 0 11.3-4 11.7-10.7.1-1.5.2-2.7.3-2.7s1.3.5 2.6 1.2"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      fill: "#1C1C1C",
      d: "M277.8 76c-.8 10.9-15.1 15.4-21.3 6.8-1.3-1.8-1.2-1.8-2.4.5-9.8 18.5 12.7 37.6 29.5 25 11.6-8.7 9.9-26.8-3.1-33.4-1.2-.6-2.3-1.1-2.4-1.1 0 0-.2 1-.3 2.2"
    }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }