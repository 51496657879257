import { acceptHMRUpdate, defineStore } from 'pinia'
import { throttle } from 'lodash-es'

import { useUserStore } from './user'
import { useSettingsGeneralStore } from './settings-general'

import { type CurrenciesValues, useValues } from '@/composables/values'
import { HttpError } from '@/helpers/errors'
import AccountService from '@/services/account/account'
import AssetsService from '@/services/account/assets'
import { dateToUTCString } from '@/services/date.service'

import type {
  AssetSourcesAsset,
  AssetSourcesAssetChild,
  BlockchainData,
  BlockchainRecord,
  BlockchainsTableParams,
  CachedAssetsTableParams,
  UpdateBlockchainData,
} from '@/types/services/assets'
import type {
  BlockchainBalance,
  CheckBlockchainData,
} from '@/types/services/account'
import type { Tag } from '@/types/services/tags'

export interface BlockchainsState {
  table: {
    data: BlockchainRecord[]
    loading: boolean
  }
  assets: {
    netValues: Map<string, CurrenciesValues>
    data: Map<string, AssetSourcesAssetChild[]>
    loading: boolean
    progress: number
  }
  detailAssets: {
    data: Map<string, AssetSourcesAssetChild[]>
    loading: boolean
    progress: number
  }
}

export const useAssetsBlockchainsStore = defineStore('assetsBlockchains', {
  state(): BlockchainsState {
    return {
      table: {
        data: [],
        loading: true, // True by default to show preloading
      },
      assets: {
        netValues: new Map(),
        data: new Map(),
        loading: false,
        progress: 0,
      },
      detailAssets: {
        data: new Map(),
        loading: false,
        progress: 0,
      },
    }
  },

  actions: {
    async getTableData(params: BlockchainsTableParams) {
      this.$patch((state) => {
        state.table.data = []
        state.table.loading = true
      })

      try {
        const response = await AssetsService.getBlockchainsData(params)

        this.$patch((state) => {
          state.table.data = response.data.data
          state.table.loading = false
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getAssets(
      params: Omit<CachedAssetsTableParams, 'return_type'>,
      forDetail = false
    ) {
      AssetsService.abortGetAssetsCached()

      const targetKey = forDetail ? 'detailAssets' : 'assets'

      this.$patch((state) => {
        if (!forDetail) {
          state.assets.netValues = new Map()
        }
        state[targetKey].data = new Map()
        state[targetKey].progress = -1
        state[targetKey].loading = true
      })

      let assets: AssetSourcesAsset[] = []
      let progress = 0
      const { findCurrenciesValues } = useValues()

      const setData = () => {
        this.$patch((state) => {
          state[targetKey].progress = progress
          assets.forEach((asset) => {
            asset.children.forEach((child) => {
              if (child.account_type != 'blockchain') {
                return
              }

              const key = child.account_id
              if (state[targetKey].data.has(key)) {
                state[targetKey].data.get(key)?.push(child)
              } else {
                state[targetKey].data.set(key, [child])
              }

              if (!forDetail) {
                const currentNetValues = state.assets.netValues.get(key)
                const childNetValues = findCurrenciesValues(
                  child.target_net_values
                )
                state.assets.netValues.set(key, {
                  fiat:
                    (currentNetValues?.fiat ?? 0) + (childNetValues?.fiat ?? 0),
                  crypto:
                    (currentNetValues?.crypto ?? 0) +
                    (childNetValues?.crypto ?? 0),
                })
              }
            })
          })

          if (progress == 100) {
            state[targetKey].loading = false
          }
        })
        assets = []
      }

      const setDataThrottled = throttle(setData, 500)

      try {
        await AssetsService.getAssetsCached(
          {
            ...params,
            return_type: 'asset_sources',
          },
          (data) => {
            if ('assets' in data) {
              assets.push(...data.assets)
              progress = data.progress

              setDataThrottled()
            }
          }
        )
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createEntry({
      name,
      entity_id,
      entry_data,
      tags,
      note,
      balances,
    }: {
      name: string
      entity_id: string
      entry_data: BlockchainData
      tags: Tag[]
      note: string | null
      balances: BlockchainBalance[]
    }) {
      const userStore = useUserStore()
      const settingsGeneralStore = useSettingsGeneralStore()

      try {
        const date = new Date()
        const createDate = dateToUTCString(date)

        const root = userStore.root

        const response = await AccountService.createAccountAndEntry(
          {
            entity_id,
            name,
            create_date: createDate,
            note,
            type: 'blockchain',
          },
          entry_data,
          balances
        )

        await settingsGeneralStore.assignAccountTags({
          accountId: response.data.data.account.id,
          rootId: root.id,
          tags,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async updateEntry({
      entry_id,
      account_id,
      entry_data,
      tags,
      entity_id,
      name,
      note,
      deactivate_date,
    }: {
      entry_id: string
      account_id: string
      entry_data: UpdateBlockchainData
      tags: Tag[]
      entity_id: string
      name: string
      note: string | null
      deactivate_date?: string
    }) {
      const userStore = useUserStore()
      const settingsGeneralStore = useSettingsGeneralStore()

      try {
        const root = userStore.root

        await settingsGeneralStore.assignAccountTags({
          accountId: account_id,
          rootId: root.id,
          tags,
        })

        await AssetsService.updateEntry(
          account_id,
          entry_id,
          'blockchain',
          entry_data
        )

        await AccountService.updateAccount(account_id, {
          entity_id,
          deactivate_date: deactivate_date,
          name,
          note,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async deleteEntry(account_id: string) {
      try {
        await AccountService.deleteAccount(account_id)
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async deactivateEntry(
      account_id: string,
      entity_id: string,
      deactivate_date: string
    ) {
      try {
        await AccountService.updateAccount(account_id, {
          entity_id,
          deactivate_date,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async checkEntry(params: CheckBlockchainData) {
      try {
        const response = await AccountService.checkBlockchain(params)

        const result = response.data.result
        if (!result.success) {
          return {
            success: false,
            message: result.message,
          }
        }

        const data = response.data.data
        return {
          success: data.success,
          balances: data.balances,
          message: data.error,
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useAssetsBlockchainsStore, import.meta.hot)
  )
}
