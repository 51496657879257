import ApiService from '@/services/api.service'
import { convertToQueryParams } from '@/helpers/objects'
import type { AxiosResponse } from 'axios'
import type {
  AllCurrenciesResponse,
  BlockchainsResponse,
  CGCategoriesResponse,
  CurrenciesResponse,
  CurrencyRatiosParams,
  CurrencyRatiosResponse,
  ExchangesResponse,
  GeckoTerminalPricesResponse,
  GetMarketDataParams,
  GetMarketDataResponse,
  GetPricesParams,
  GetPricesResponse,
  ICOPriceResponse,
  SourcesResponse,
} from '@/types/services/currencies'
import type { StringDataResponse } from '@/types/services/common'

class CurrenciesService {
  public static readonly NOT_MODIFIED = 'NOT_MODIFIED'
  protected static _curr_last_modified: string | null = null

  static async getAllCurrencies(): Promise<
    AxiosResponse<AllCurrenciesResponse> | string
  > {
    const url = `${import.meta.env.VUE_APP_STATIC_FILES_URL}/currencies.json?_=${Date.now()}`

    const headResponse = await ApiService.customRequest({
      url,
      method: 'HEAD',
    })

    const currentLastModified = headResponse.headers['last-modified']

    if (CurrenciesService._curr_last_modified === currentLastModified) {
      return CurrenciesService.NOT_MODIFIED
    }

    const dataResponse = await ApiService.customRequest({
      url,
      method: 'GET',
    })

    // We need to use the last-modified from the headResponse in this case because we are using the StaleWhileRevalidate
    // strategy in the service worker, which allows the dataResponse to be retrieved from the cache.
    CurrenciesService._curr_last_modified = currentLastModified

    return dataResponse
  }

  static getBlockchains(): Promise<AxiosResponse<BlockchainsResponse>> {
    return ApiService.post(`/api/v1/currency/search/get_blockchains`, {
      aggr: {
        params: {},
      },
    })
  }

  static getExchanges(): Promise<AxiosResponse<ExchangesResponse>> {
    return ApiService.post(`/api/v1/currency/search/get_exchanges`, {
      aggr: {
        params: {},
      },
    })
  }

  // NOT USED
  static getFiatCurrencies(): Promise<AxiosResponse<CurrenciesResponse>> {
    return ApiService.post(`/api/v1/currency/search/get_fiat_currencies`, {
      aggr: {
        params: {},
      },
    })
  }

  // NOT USED
  static getCryptoCurrencies(
    limit = 400
  ): Promise<AxiosResponse<CurrenciesResponse>> {
    return ApiService.post(
      `/api/v1/currency/search/get_top_50_crypto_currencies`,
      {
        aggr: {
          params: {
            limit,
          },
        },
      }
    )
  }

  static getCurrencyRatios(
    params: CurrencyRatiosParams,
    allowConcurrentRequests = false
  ): Promise<AxiosResponse<CurrencyRatiosResponse>> {
    return ApiService.post(
      `/api/v1/currency/get_ratio`,
      params,
      allowConcurrentRequests
    )
  }

  static getICOPrice(
    icoID: string,
    outputCurrencyId?: string
  ): Promise<AxiosResponse<ICOPriceResponse>> {
    let url = `/api/v1/icos/${icoID}/price`
    if (outputCurrencyId) {
      url += `?output_currency_id=${encodeURIComponent(outputCurrencyId)}`
    }
    return ApiService.get(url)
  }

  static getSources(): Promise<AxiosResponse<SourcesResponse>> {
    return ApiService.post(`/api/v1/currency/search/get_sources`, {
      aggr: {
        params: {},
      },
    })
  }

  static getPrices(
    params: GetPricesParams
  ): Promise<AxiosResponse<GetPricesResponse>> {
    return ApiService.post('/api/v1/currency/get_prices', params)
  }

  static checkGeckoTerminalPrice(
    currency_id: string,
    platform: string
  ): Promise<AxiosResponse<GeckoTerminalPricesResponse>> {
    return ApiService.post('/api/v1/currency/check_geckoterminal_price', {
      currency_id,
      platform,
    })
  }

  static registerForGeckoTerminal(
    currency_id: string,
    platform: string,
    contract_address: string,
    pool_ids: string[]
  ): Promise<AxiosResponse<StringDataResponse>> {
    return ApiService.post(
      '/api/v1/currency/register_for_geckoterminal',
      {
        currency_id,
        platform,
        contract_address,
        pool_ids,
      },
      true
    )
  }

  static getCGCategories(): Promise<AxiosResponse<CGCategoriesResponse>> {
    return ApiService.get('/api/v1/currency/get_cg_categories')
  }

  static getMarketData(
    params: GetMarketDataParams
  ): Promise<AxiosResponse<GetMarketDataResponse>> {
    const query = convertToQueryParams(params)
    return ApiService.get(`/api/v1/market_data/get_market_data?${query}`)
  }
}

export default CurrenciesService
