<template>
  <div
    :id="id"
    v-click-outside="handleClickOutside"
    class="crypkit-select"
    :class="[
      size,
      { 'validation-error': !!errorMessage },
      { collapsed: collapsed, inline: inline, disabled: disabled },
    ]"
    :data-test-label="label"
  >
    <div class="select-wrapper">
      <Label
        v-if="label !== ''"
        :label="label"
        :required="required"
        :hide-asterisk="hideAsterisk"
        :help="help"
      >
        <template v-if="$slots['label-append']" #append>
          <slot name="label-append" />
        </template>
      </Label>

      <div
        ref="select"
        class="select"
        :class="{
          'bulky-border': bulkyBorder,
        }"
        tabindex="0"
        @keyup.enter="selectHovered"
        @keyup.esc="closeSelect"
        @keydown.up.prevent="handleArrowKey(ArrowKeyDirection.UP)"
        @keydown.down.prevent="handleArrowKey(ArrowKeyDirection.DOWN)"
        @click.prevent.stop="collapsed ? closeSelect() : openSelect()"
      >
        <slot name="icon-before" />
        <transition name="fade" mode="out-in">
          <span
            v-if="$slots['selected-text-prepend']"
            :key="getSlotKeyByChildren($slots['selected-text-prepend']()[0])"
            class="flex items-center pr-2 mr-2 border-r border-outline h-3/5"
          >
            <slot name="selected-text-prepend" />
          </span>
        </transition>
        <input
          v-if="searchable && collapsed"
          ref="search-input"
          class="outline-none text-sm pr-2"
          type="text"
          @input="debounceSearch"
          @click.stop
        />
        <span
          v-else
          class="selected-text"
          :class="{
            placeholder: noneSelected,
            'selected-text-multiple':
              !noneSelected && Array.isArray(modelValue),
          }"
        >
          <template v-if="noneSelected">{{ placeholder }}</template>
          <slot v-else name="selected-text" :selected="modelValue">
            {{ selectedText }}
          </slot>
        </span>

        <div class="icon">
          <span
            v-if="collapsed && clearable"
            class="clear-select"
            @click.stop="reset"
          >
            <SvgIcon icon="Close" class="flex-shrink-0" block />
          </span>
          <slot v-else name="icon">
            <SvgIcon icon="ArrowDown" class="caret" />
          </slot>
        </div>

        <SelectItems
          v-if="collapsed"
          :id="id ? `${id}-items` : null"
          ref="items"
          v-model:collapsed="collapsed"
          :class="itemsClass"
          :position-to="$refs['select'] as HTMLDivElement"
          :options="filteredOptions"
          :text-key="selectedTextKey"
          :selected-value="modelValue"
          :group-values="groupValues"
          :no-options-text="noOptionsText"
          @load-more="$emit('load-more')"
          @select="selectValue"
        >
          <template v-if="$slots['after-options']" #after-options>
            <slot name="after-options" />
          </template>
          <template #item-text="slotProps">
            <slot name="item-text" :option="slotProps.option" />
          </template>
        </SelectItems>
      </div>
    </div>

    <div v-if="!hideErrors" class="validation-messages">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import './Select.scss'

import { defineComponent, toRef, type VNode } from 'vue'
import { useField } from 'vee-validate'
import { debounce } from 'lodash-es'
import { isEqual } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import hash from 'object-hash'

import SelectItems from '@/components/controls/SelectItems'
import SvgIcon from '@/components/misc/SvgIcon'
import Label from '@/components/misc/Label'

import ClickOutside from '@/components/directives/click-outside'

import fuzzySearch from '@/helpers/fuzzySearch'

import type { PropType } from 'vue'

export enum ArrowKeyDirection {
  UP = 'up',
  DOWN = 'down',
}

export default defineComponent({
  directives: {
    ClickOutside,
  },

  components: {
    SvgIcon,
    Label,
    SelectItems,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: () => uuidv4(),
    },
    modelValue: {
      type: [Object, Array] as PropType<object | object[] | null>,
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideAsterisk: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: [Object, Array] as PropType<object | object[] | null>,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    customSearchHandler: {
      type: Function,
      default: null,
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    noOptionsText: {
      type: String,
      default: 'No results found',
    },
    selectedTextKey: {
      type: String,
      default: 'text',
    },
    groupValues: {
      type: String,
      default: null,
    },
    bulkyBorder: {
      type: Boolean,
      default: false,
    },
    itemsClass: {
      type: String,
      default: null,
    },
  },

  emits: ['load-more', 'update:modelValue'],

  setup(props) {
    const name = toRef(props, 'name')
    const initialValue = toRef(props, 'modelValue')

    const { value: inputValue, errorMessage } = useField(name, undefined, {
      initialValue,
      validateOnValueUpdate: false,
    })

    return {
      errorMessage,
      inputValue,
    }
  },

  data() {
    return {
      debounceSearch: debounce(() => {}),
      search: '',
      collapsed: false,
      ArrowKeyDirection: Object.freeze(ArrowKeyDirection),
      selectedValues: [] as object[],
    }
  },

  computed: {
    noneSelected(): boolean {
      const { inputValue } = this
      return (
        inputValue == null ||
        (Array.isArray(inputValue) && inputValue.length == 0)
      )
    },
    selectedText(): string {
      const { inputValue, selectedTextKey } = this

      if (!inputValue) {
        return ''
      }

      if (Array.isArray(inputValue)) {
        if (inputValue.length > 1) {
          return `${inputValue.length} options selected`
        } else if (inputValue.length == 1) {
          return inputValue[0][selectedTextKey]
        }
        return ''
      }

      return selectedTextKey in inputValue ? inputValue[selectedTextKey] : ''
    },
    filteredOptions(): object[] {
      const { searchable, search, customSearchHandler, groupValues } = this
      const options = this.options

      if (Array.isArray(this.inputValue)) {
        // selected items on top when multiple is set
        options.sort((a, b) => {
          const isASelected = this.isSelected(a)
          const isBSelected = this.isSelected(b)
          if (isASelected && !isBSelected) {
            return -1
          } else if (!isASelected && isBSelected) {
            return 1
          }
          return 0
        })
      }

      if (customSearchHandler != null) {
        return options
      }

      if (searchable && search.length > 0) {
        if (groupValues) {
          const filteredGroups: object[] = []

          for (let i = 0; i < options.length; i++) {
            const option = options[i]
            const groupOptions = fuzzySearch(search, option[groupValues])
            if (groupOptions.length) {
              const filteredGroup = { ...option }
              filteredGroup[groupValues] = groupOptions
              filteredGroups.push(filteredGroup)
            }
          }
          return filteredGroups
        }
        return fuzzySearch(search, options, { keys: [this.selectedTextKey] })
      } else {
        // delete old search highlighted results
        options.forEach((option: any) => delete option.highlight)
      }

      return options
    },
  },

  watch: {
    collapsed(collapsed) {
      if (this.searchable && collapsed) {
        this.$nextTick(() => {
          const searchInput = this.$refs['search-input'] as HTMLElement
          searchInput.focus()
        })
      }
    },
    selectedOption: {
      immediate: true,
      handler(val) {
        if (val) {
          this.inputValue = val
        }
      },
    },
    search(value) {
      if (this.customSearchHandler != null) {
        this.customSearchHandler(value)
      }
    },
    modelValue(value) {
      if (this.inputValue != value) {
        this.inputValue = value
      }
    },
  },

  created() {
    this.debounceSearch = debounce((e: InputEvent) => {
      if (e.target) {
        const input = e.target as HTMLInputElement
        this.search = input.value
      }
    }, 100)
  },

  methods: {
    getSlotKeyByChildren(node: VNode) {
      return hash(node, {
        replacer: (value) => {
          if (value && typeof value === 'object') {
            if ('children' in value) {
              return value.children
            }
            return null
          }
          return value
        },
      })
    },
    selectValue(value: object) {
      const { groupValues } = this
      if (value && groupValues && Array.isArray(value[groupValues])) {
        // do not select group header
        return
      }

      if (Array.isArray(this.inputValue)) {
        const idx = this.inputValue.findIndex((item) => isEqual(item, value))
        if (idx !== -1) {
          this.inputValue.splice(idx, 1)
        } else {
          this.inputValue.push(value)
        }
        if (this.searchable) {
          const searchInput = this.$refs['search-input'] as HTMLInputElement
          searchInput.focus()
        }
      } else {
        this.inputValue = value
        this.closeSelect()
      }

      this.$emit('update:modelValue', this.inputValue)
    },
    selectHovered() {
      const items = this.$refs.items as InstanceType<typeof SelectItems>
      if (items && items.hasHovered()) {
        items.selectHovered()
      }
    },
    reset() {
      this.inputValue = Array.isArray(this.inputValue) ? [] : null
      this.$emit('update:modelValue', this.inputValue)
      this.closeSelect()
    },
    openSelect() {
      if (this.disabled) {
        return
      }
      this.collapsed = true
    },
    closeSelect() {
      this.collapsed = false
      this.search = ''
    },
    handleClickOutside(event: Event) {
      // check if was clicked on any select item
      const items = this.$refs.items as InstanceType<typeof SelectItems>
      if (items && !items.$el.contains(event.target as HTMLElement)) {
        this.closeSelect()
      }
    },
    handleArrowKey(direction: ArrowKeyDirection) {
      if (!this.collapsed) {
        this.collapsed = true
        return
      }

      const items = this.$refs.items as InstanceType<typeof SelectItems>
      if (direction == ArrowKeyDirection.UP) {
        items.hoverPrev()
      } else {
        items.hoverNext()
      }
    },
    isSelected(option: object): boolean {
      if (this.noneSelected) {
        return false
      }

      return Array.isArray(this.inputValue)
        ? this.inputValue.findIndex((item) => isEqual(item, option)) !== -1
        : isEqual(this.inputValue, option)
    },
  },
})
</script>
