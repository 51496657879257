import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13.28 10.31V4.37a1.32 1.32 0 0 0-1.32-1.32H4.04a1.32 1.32 0 0 0-1.32 1.32v5.94m10.56 0H2.72m10.56 0 .845 1.683a.66.66 0 0 1-.594.957H2.47a.66.66 0 0 1-.594-.957l.845-1.683" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }