import axios, { AxiosError } from 'axios'
import JsSHA1 from 'jssha/dist/sha1'
import { v4 as uuidv4 } from 'uuid'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { useEventBus } from '@/composables/event_bus'
import TokenService from '@/services/user/token'
import UserService from '@/services/user/user'

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  Method,
} from 'axios'
import type { EventSourceMessage } from '@microsoft/fetch-event-source/lib/cjs/parse'

type RefreshSubscriberCallback = (token: string) => void
type ErrorListenerCallback = (error: any, correlationId: string) => void

class UnauthorizedError extends Error {}
class RefreshTokenError extends Error {}

interface CancelSources {
  [key: string]: CancelTokenSource
}

interface StreamAbortControllers {
  [key: string]: AbortController
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  expected404?: boolean
}

const CANCEL_REQUEST_MESSAGE = 'Request canceled'
const CANNOT_OPEN_DATA_STREAM_MESSAGE = 'Cannot open data stream'
const AUTH_HEADER = 'Authorization'
const CORRELATION_ID_HEADER = 'X-Correlation-ID'

const eventBus = useEventBus()

class ApiService {
  private isRefreshing = false
  private refreshSubscribers: RefreshSubscriberCallback[] = []
  private baseUrlV1 = ''
  private baseUrlV2 = ''
  private axiosInstance: AxiosInstance
  private _401interceptor: number | null = null
  private cancelSources: CancelSources = {}
  private streamAbortControllers: StreamAbortControllers = {}
  private errorListener: ErrorListenerCallback | null = null

  private static instance: ApiService

  private constructor() {
    this.axiosInstance = axios.create({
      // headers: {'X-Custom-Header': 'foobar'}
    })
    this.mountErrorInterceptor()
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService()
    }

    return ApiService.instance
  }

  public init(baseUrlV1: string, baseUrlV2: string) {
    this.baseUrlV1 = baseUrlV1
    this.baseUrlV2 = baseUrlV2
  }

  public setHeader() {
    this.axiosInstance.defaults.headers.common[AUTH_HEADER] =
      `${TokenService.getAuthToken()}`
  }

  public removeHeader() {
    this.axiosInstance.defaults.headers.common = {}
  }

  public async get(
    resource: string,
    allowConcurrentRequests?: boolean,
    expected404?: boolean
  ): Promise<AxiosResponse> {
    return this.performRequest(
      resource,
      'GET',
      null,
      allowConcurrentRequests,
      expected404
    )
  }

  public async post(
    resource: string,
    data: any,
    allowConcurrentRequests?: boolean,
    expected404?: boolean
  ): Promise<AxiosResponse> {
    return this.performRequest(
      resource,
      'POST',
      data,
      allowConcurrentRequests,
      expected404
    )
  }

  public async put(
    resource: string,
    data: any,
    allowConcurrentRequests?: boolean
  ): Promise<AxiosResponse> {
    return this.performRequest(resource, 'PUT', data, allowConcurrentRequests)
  }

  public async patch(
    resource: string,
    data: any,
    allowConcurrentRequests?: boolean
  ): Promise<AxiosResponse> {
    return this.performRequest(resource, 'PATCH', data, allowConcurrentRequests)
  }

  public async delete(
    resource: string,
    data?: any,
    allowConcurrentRequests?: boolean
  ): Promise<AxiosResponse> {
    return this.performRequest(
      resource,
      'DELETE',
      data,
      allowConcurrentRequests
    )
  }

  public async getStream(
    resource: string,
    method: 'GET' | 'POST',
    onMessage: (ev: EventSourceMessage) => void,
    onClose?: () => void,
    body?: any
  ): Promise<void> {
    let wasTokenRefreshed = false

    const hash = ApiService.getHash(resource, method)

    this.abortStreamByHash(hash)

    const abortController = new AbortController()
    this.streamAbortControllers[hash] = abortController

    const url = this.getResourceUrl(resource)
    const correlationID = uuidv4()
    const data = JSON.stringify(body)

    return fetchEventSource(url, {
      method,
      headers: {
        Authorization: TokenService.getAuthToken() ?? '',
        'Content-Type': 'application/json',
        [CORRELATION_ID_HEADER]: correlationID,
      },
      async onopen(response) {
        if (response.ok) {
          return Promise.resolve()
        }
        if (response.status == 401) {
          try {
            await UserService.refreshToken()
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (e) {
            throw new RefreshTokenError()
          }
          wasTokenRefreshed = true
          throw new UnauthorizedError()
        }
        throw new Error(CANNOT_OPEN_DATA_STREAM_MESSAGE)
      },
      onmessage: onMessage,
      onclose: () => {
        delete this.streamAbortControllers[hash]
        if (onClose) {
          onClose()
        }
      },
      onerror: (err) => {
        if (err instanceof UnauthorizedError && !wasTokenRefreshed) {
          return
        }

        if (this.errorListener) {
          this.errorListener(
            {
              name: err.name,
              message: err.message,
              stack: err.stack,
              config: {
                url,
                method,
                data,
              },
            },
            correlationID
          )
        }

        // Throw the error again to propagate it to the caller
        throw err
      },
      body: data,
      signal: abortController.signal,
      openWhenHidden: true,
    })
  }

  public abortStreamByUrl(resource: string, method: 'GET' | 'POST') {
    const hash = ApiService.getHash(resource, method)
    this.abortStreamByHash(hash)
  }

  public abortStreamByHash(hash: string) {
    if (hash in this.streamAbortControllers) {
      this.streamAbortControllers[hash].abort()
      delete this.streamAbortControllers[hash]
    }
  }

  public async customRequest(
    data: CustomAxiosRequestConfig
  ): Promise<AxiosResponse> {
    if (data.url && /\/api\/v[0-9]/.test(data.url)) {
      if (!data.headers) {
        data.headers = {}
      }
      data.headers[CORRELATION_ID_HEADER] = uuidv4()
    }
    return this.axiosInstance.request(data)
  }

  protected async performRequest(
    resource: string,
    method: Method,
    data?: any,
    allowConcurrentRequests?: boolean,
    expected404?: boolean
  ): Promise<AxiosResponse> {
    const url = this.getResourceUrl(resource)
    const hash = ApiService.getHash(url, method)

    /**
     * Cancel the ongoing previous request to the same endpoint,
     * because if it is slower than the new one,
     * it will overwrite the required data.
     */
    if (!allowConcurrentRequests && hash in this.cancelSources) {
      this.cancelSources[hash].cancel(CANCEL_REQUEST_MESSAGE)
    }

    const cancelSource = axios.CancelToken.source()
    this.cancelSources[hash] = cancelSource

    try {
      const response = await this.customRequest({
        url,
        method,
        data,
        cancelToken: cancelSource?.token,
        expected404,
      })
      delete this.cancelSources[hash]
      return response
    } catch (e) {
      if (!axios.isCancel(e)) {
        delete this.cancelSources[hash]
      }
      if (
        expected404 &&
        e instanceof AxiosError &&
        e.response?.status === 404
      ) {
        return e.response
      }
      throw e
    }
  }

  public async liveCheck() {
    try {
      await this.performRequest(`?_=${Date.now()}`, 'HEAD')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      console.warn(
        'Failed to get HEAD of index. Probably undergoing maintenance.'
      )
    }
  }

  public cancelRequests(resource: string, method: Method) {
    const url = this.getResourceUrl(resource)
    const hash = ApiService.getHash(url, method)

    if (hash in this.cancelSources) {
      this.cancelSources[hash].cancel(CANCEL_REQUEST_MESSAGE)
      delete this.cancelSources[hash]
    }
  }

  public cancelCurrentRequests() {
    Object.keys(this.cancelSources).forEach((key) => {
      this.cancelSources[key].cancel(CANCEL_REQUEST_MESSAGE)
      delete this.cancelSources[key]
    })
  }

  public getResourceUrl(resource: string): string {
    if (resource.indexOf('://') !== -1 || resource == '/version.json') {
      return resource
    } else if (resource.indexOf('/v2/') !== -1) {
      return `${this.baseUrlV2}${resource}`
    } else {
      return `${this.baseUrlV1}${resource}`
    }
  }

  private mountErrorInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error instanceof AxiosError) {
          const status = error.response?.status ?? 0
          const config = error.config as CustomAxiosRequestConfig | undefined
          if (this.errorListener && (!config?.expected404 || status !== 404)) {
            this.errorListener(
              error,
              error.config?.headers[CORRELATION_ID_HEADER] ?? 'unknown'
            )
          }
          if (status === 503) {
            eventBus.emit('showMaintenance')
          }
        }

        return Promise.reject(error)
      }
    )
  }

  public mount401Interceptor() {
    this._401interceptor = this.axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (
          error.message === CANCEL_REQUEST_MESSAGE ||
          error.response?.status !== 401 ||
          error.config?._retry
        ) {
          return Promise.reject(error)
        }

        // If this request was to refresh token or logout and it failed
        // don't add it to subscribers, reject it instead
        // FIXME: This would break if endpoints change in the future
        if (
          error.config.url.includes('refresh_token') ||
          error.config.url.includes('logout')
        ) {
          return Promise.reject(error)
        }

        // Save original request
        const originalRequest = error.config

        // Trigger token refresh that will then
        // replay all failed and subscribed requests
        if (!this.isRefreshing) {
          this.isRefreshing = true
          UserService.refreshToken()
            .then((newToken) => {
              this.isRefreshing = false
              this.onRefreshed(newToken)
            })
            .catch((err) => {
              // 401 is the expected response if the token has expired.
              // The service will logout on every error.
              if (err.response?.status == 401) {
                return Promise.resolve()
              }
              return Promise.reject(err)
            })
        }

        // Add failed request to subscribers
        return new Promise((resolve) => {
          this.subscribeTokenRefresh((token: string) => {
            // replace the expired token and retry
            originalRequest.headers[AUTH_HEADER] = token
            // flag to prevent failed retried requests to
            // cause an infinite loop of requests
            originalRequest._retry = true
            resolve(this.axiosInstance(originalRequest))
          })
        })
      }
    )
  }

  public unmount401Interceptor() {
    // Eject the interceptor
    if (this._401interceptor) {
      this.axiosInstance.interceptors.response.eject(this._401interceptor)
    }
  }

  public subscribeTokenRefresh(cb: RefreshSubscriberCallback): void {
    this.refreshSubscribers.push(cb)
  }

  public onRefreshed(token: string) {
    while (this.refreshSubscribers.length > 0) {
      const cb = this.refreshSubscribers.shift() as RefreshSubscriberCallback
      cb(token)
    }
  }

  public setErrorListener(cb: ErrorListenerCallback) {
    this.errorListener = cb
  }

  private static getHash(path: string, method: string = 'GET'): string {
    const hash = new JsSHA1('SHA-1', 'TEXT', { encoding: 'UTF8' })
    hash.update(`${method}:${path}`)
    return hash.getHash('HEX')
  }
}

export default ApiService.getInstance()
