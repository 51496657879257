import { acceptHMRUpdate, defineStore } from 'pinia'
import * as Sentry from '@sentry/browser'

import { useUserStore } from './user'

import { convertCurrencyArray } from '@/helpers/currencies'
import { HttpError } from '@/helpers/errors'
import { friendlyValuationMethods } from '@/helpers/friendlyNames'
import { formatDateWithTime } from '@/services/date.service'
import CurrenciesService from '@/services/currency/currencies'
import UserService from '@/services/user/user'

import type {
  AllCurrenciesResponse,
  CoinGeckoCategory,
  CurrencyArray,
} from '@/types/services/currencies'
import type {
  Blockchain,
  Currency,
  CurrencyMap,
  Exchange,
  Permission,
  Source,
  Timezone,
  ValuationMethod,
  ValuationMethodType,
} from '@/types/services/global'
import ServiceError from '@/errors/ServiceError'
import { capitalize } from 'lodash-es'

const convertToCurrencies = (
  records: CurrencyArray[],
  group: string
): { list: Currency[]; map: CurrencyMap } => {
  const list: Currency[] = []
  const map: CurrencyMap = {}

  records.forEach((record) => {
    const currency = convertCurrencyArray(record, group)
    list.push(currency)
    map[currency.id] = currency
  })

  return {
    list,
    map,
  }
}

export interface TimeoutOption {
  text: string
  value: number
}

export interface Ratio {
  ratio: number | null
  loading: boolean
}

export interface SegmentsMap {
  [key: string]: string
}

export interface GlobalState {
  showMaintenance: boolean
  currenciesLastModified: string | null
  currenciesFiat: Currency[]
  currenciesCrypto: Currency[]
  currenciesStable: Currency[]
  currenciesOthers: Currency[]
  currenciesFake: Currency[]
  currenciesMap: CurrencyMap
  segmentsMap: Map<string, string>
  cgCategoriesMap: Map<string, CoinGeckoCategory>
  currenciesRatio: Ratio
  exchanges: Exchange[]
  blockchains: Blockchain[]
  perPageOptions: { value: number; text: string }[]
  sidebarToggled: boolean
  isSmallScreen: boolean
  activeViewName: string
  collapsedMenuIndex: number | null
  timezones: Timezone[]
  valuation_methods: (ValuationMethod & { text: string })[]
  permissions: Permission[]
  sourcesMap: Map<string, Source>
  sourcesLoading: boolean
  customHeader: boolean
  sessionTimeoutOptions: TimeoutOption[]
}

export interface PermissionsTypeCount {
  read: number
  write: number
}

export const HIDDEN_PERMISSION_CATEGORIES = [
  'CEX',
  'Trades',
  'Loans',
  'Staking',
  'Mining',
  'Rebalance',
  'Portfolio maker',
]

export const useGlobalStore = defineStore('global', {
  state(): GlobalState {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    const isSmallScreen = vw <= 1366

    return {
      showMaintenance: false,
      currenciesLastModified: null,
      currenciesFiat: [],
      currenciesCrypto: [],
      currenciesStable: [],
      currenciesOthers: [],
      currenciesFake: [],
      currenciesMap: {},
      segmentsMap: new Map(),
      cgCategoriesMap: new Map(),
      currenciesRatio: {
        // selected fiat and crypto currencies ratio
        ratio: null,
        loading: true,
      },
      exchanges: [],
      blockchains: [],
      perPageOptions: [
        {
          value: 10,
          text: '10',
        },
        {
          value: 25,
          text: '25',
        },
        {
          value: 50,
          text: '50',
        },
        {
          value: 100,
          text: '100',
        },
      ],
      sidebarToggled: !isSmallScreen,
      isSmallScreen,
      activeViewName: '',
      collapsedMenuIndex: null,
      timezones: [],
      valuation_methods: [],
      permissions: [],
      sourcesMap: new Map(),
      sourcesLoading: false,
      customHeader: false,
      sessionTimeoutOptions: [
        // In milliseconds (for JS timeouts)
        {
          text: '10 Minutes',
          value: 600000,
        },
        {
          text: '30 Minutes',
          value: 1800000,
        },
        {
          text: '60 Minutes',
          value: 3600000,
        },
        {
          text: '2 hours',
          value: 7200000,
        },
        {
          text: '6 hours',
          value: 21600000,
        },
        {
          text: '12 hours',
          value: 43200000,
        },
        {
          text: '1 day',
          value: 86400000,
        },
        {
          text: '1 week',
          value: 604800000,
        },
        {
          text: '2 weeks',
          value: 1209600000,
        },
      ],
    }
  },

  actions: {
    async getAllCurrencies() {
      try {
        const response = await CurrenciesService.getAllCurrencies()
        if (typeof response === 'string') {
          if (response === CurrenciesService.NOT_MODIFIED) {
            return
          } else {
            throw new ServiceError('Unknown getAllCurrencies response')
          }
        }

        this.setAllCurrencies(
          response.data,
          response.headers['last-modified'] ?? null
        )
      } catch (error) {
        if (error instanceof ServiceError) {
          Sentry.captureException(error)
          throw error
        }
        throw new HttpError(error)
      }
    },

    // Since we are using the StaleWhileRevalidate strategy in the service worker, we need to check the Last-Modified
    // header to avoid setting the same data again, allowing us to wait for the update event.
    setAllCurrencies(
      currencies: AllCurrenciesResponse,
      modifiedAt: string | null
    ) {
      if (modifiedAt == this.currenciesLastModified) {
        return
      }

      const fiat = convertToCurrencies(currencies.fiat, 'fiat')

      const crypto = convertToCurrencies(currencies.crypto, 'crypto')
      const stable = convertToCurrencies(currencies.stable, 'stable')
      const others = convertToCurrencies(currencies.others, 'others')
      const fake = convertToCurrencies(currencies.fake, 'fake')

      this.$patch((state) => {
        state.currenciesLastModified = modifiedAt
        state.currenciesFiat = fiat.list
        state.currenciesCrypto = crypto.list
        state.currenciesStable = stable.list
        state.currenciesOthers = others.list
        state.currenciesFake = fake.list
        state.currenciesMap = {
          ...fiat.map,
          ...crypto.map,
          ...stable.map,
          ...others.map,
          ...fake.map,
        }
        state.segmentsMap = new Map(
          currencies.segments.map((segment) => [segment.id, segment.name])
        )
        state.cgCategoriesMap = new Map(
          currencies.categories_cg.map((cat) => [cat.id, cat])
        )
      })
    },

    async getCurrencyRatios() {
      const userStore = useUserStore()
      const entity = userStore.entity || userStore.clientEntity

      if (!userStore.currencyFiat || !userStore.currencyCrypto || !entity) {
        return
      }

      this.$patch((state) => {
        state.currenciesRatio.ratio = null
        state.currenciesRatio.loading = true
      })

      try {
        const response = await CurrenciesService.getCurrencyRatios({
          base_id: userStore.currencyFiat.id,
          target_id: userStore.currencyCrypto.id,
          valuation_method: entity.valuation_method,
        })
        const ratio = response.data.data
        this.currenciesRatio.ratio = ratio != null ? parseFloat(ratio) : null
      } catch (error) {
        throw new HttpError(error)
      } finally {
        this.currenciesRatio.loading = false
      }
    },

    async getGeneral() {
      try {
        const response = await UserService.getGeneral()
        const data = response.data.data

        // hide services write permission or specific categories
        const permissions = data.permissions.filter(
          (permission) =>
            permission.id != 293 &&
            !HIDDEN_PERMISSION_CATEGORIES.includes(permission.name) &&
            !HIDDEN_PERMISSION_CATEGORIES.includes(permission.category)
        )

        this.$patch({
          timezones: data.timezones.map((tz) => ({
            ...tz,
            text: tz.name,
          })),
          valuation_methods: data.valuation_methods.map((vm) => {
            return {
              ...vm,
              text: friendlyValuationMethods[vm.name],
            }
          }),
          permissions: permissions,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getExchanges() {
      try {
        const response = await CurrenciesService.getExchanges()
        this.exchanges = response.data.data
      } catch (error) {
        throw new HttpError(error)
      }
    },

    async getBlockchains() {
      try {
        const response = await CurrenciesService.getBlockchains()
        this.blockchains = response.data.data
      } catch (error) {
        throw new HttpError(error)
      }
    },

    async getSources() {
      this.sourcesLoading = true
      try {
        const response = await CurrenciesService.getSources()
        const data = response.data.data
        const map = new Map<string, Source>()
        for (let i = 0; i < data.length; i++) {
          const source = data[i]
          map.set(source.id, source)
        }
        this.sourcesMap = map
      } catch (error) {
        throw new HttpError(error)
      } finally {
        this.sourcesLoading = false
      }
    },

    toggleSidebar() {
      this.sidebarToggled = !this.sidebarToggled
    },
  },

  getters: {
    allCurrencies(state) {
      return [
        ...state.currenciesFiat,
        ...state.currenciesStable,
        ...state.currenciesCrypto,
        ...state.currenciesFake,
      ]
    },

    currencyById(state) {
      return (currencyId: string): Currency | null => {
        return currencyId in state.currenciesMap
          ? state.currenciesMap[currencyId]
          : null
      }
    },

    currencyBySymbol() {
      return (symbol: string): Currency | null => {
        return (
          this.allCurrencies.find((cur: Currency) => {
            return cur.symbol == symbol
          }) || null
        )
      }
    },

    blockchainByCurrencyId(state) {
      return (currencyId: string): Blockchain | null => {
        return (
          state.blockchains.find((b) => b.currency_id === currencyId) || null
        )
      }
    },

    exchangeById(state) {
      return (id: string): Exchange | null => {
        return state.exchanges.find((e: Exchange) => e.id === id) || null
      }
    },

    sourceById(state) {
      return (sourceId: string): Source | null => {
        return state.sourcesMap.get(sourceId) || null
      }
    },

    getCGCategoryName: (state) => (categoryId: string) => {
      return (
        state.cgCategoriesMap.get(categoryId)?.name ?? capitalize(categoryId)
      )
    },

    getSegmentName: (state) => (segmentId: string) => {
      return state.segmentsMap.get(segmentId) ?? segmentId
    },

    timezoneById(state) {
      return (timezoneId: string): Timezone | null => {
        return (
          state.timezones.find((tz: Timezone) => tz.id === timezoneId) || null
        )
      }
    },

    valuationMethodByName(state) {
      return (valuationMethodName: ValuationMethodType) => {
        return (
          state.valuation_methods.find(
            (vm: ValuationMethod) => vm.name === valuationMethodName
          ) || null
        )
      }
    },

    utcOpenByTimezone() {
      return (
        date: Date,
        timezoneId: string | null,
        notFuture = false
      ): string => {
        let diff = 0

        if (timezoneId != null) {
          const timezone = this.timezoneById(timezoneId)
          if (timezone && timezone.time_offset) {
            diff = -1 * parseFloat(timezone.time_offset) * 60
          }
        }

        return formatDateWithTime(
          date,
          diff,
          `yyyy-MM-dd'T'HH:mm:00'+00:00'`,
          notFuture
        )
      }
    },

    utcCloseByTimezone() {
      return (
        date: Date,
        timezoneId: string | null,
        notFuture = false
      ): string => {
        let diff = 0

        if (timezoneId != null) {
          const timezone = this.timezoneById(timezoneId)
          if (timezone && timezone.time_offset) {
            const endOfDayInMins = 24 * 60 - 1
            diff = endOfDayInMins - parseFloat(timezone.time_offset) * 60
          }
        }

        return formatDateWithTime(
          date,
          diff,
          `yyyy-MM-dd'T'HH:mm:59'+00:00'`,
          notFuture
        )
      }
    },

    permissionsTypeCount(state): PermissionsTypeCount {
      const userStore = useUserStore()
      const counts: PermissionsTypeCount = {
        read: 0,
        write: 0,
      }

      state.permissions.forEach((permission) => {
        // Filters permissions to show only those
        // that are included in user's packages
        if (userStore.hasPackage(permission.package_id.split(','))) {
          if (permission.type == 'read') {
            counts.read++
          } else if (permission.type == 'write') {
            counts.write++
          }
        }
      })
      return counts
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}
